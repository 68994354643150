import React from "react"
import { 
  graphql,   
  Link as GatsbyLink
} from "gatsby"
import parse from "html-react-parser"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Providers from "../components/providers"

import { 
  Box,
  Container, 
  Flex, 
  Heading,
  Link,
  Text,
} from "@chakra-ui/react"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <Providers>
        <Layout>
          <SEO title="All posts" />
          <Container maxW="container.md">
            <Flex as="header" flexDirection="column" paddingTop="32">
              <Heading as="h1" size="4xl" itemProp="headline">Blog</Heading>
              <hr />
            </Flex>
          </Container>
          <Container maxW="container.md">
            <Text>
              No blog posts found.
            </Text>          
          </Container>
        </Layout>
      </Providers>
    )
  }

  return (
    <Providers>
      <Layout>
        <SEO title="All posts" />
        <Container maxW="container.md">
          <Flex as="header" flexDirection="column" paddingTop="32">
            <Heading as="h1" size="2xl" itemProp="headline">Blog</Heading>
            <hr />
          </Flex>
        </Container>
        <Container maxW="container.md">
          <ol style={{ listStyle: `none` }}>
            {posts.map(post => {
              const title = post.title

              return (
                <li key={post.uri}>
                  <article
                    className="post-list-item"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <header>
                      <Heading as="h2" size="xl">
                        <Link as={GatsbyLink} to={post.uri} itemProp="url">
                          <span itemProp="headline">{parse(title)}</span>
                        </Link>
                      </Heading>
                      <Text mt="2" mb="2">{post.date}</Text>
                    </header>
                    <Box as="section" itemProp="description">{parse(post.excerpt)}</Box>
                    <Box mt="2">
                      <Link as={GatsbyLink} to={post.uri} itemProp="url" textDecoration="underline">
                        Continue reading...
                      </Link>
                    </Box>
                  </article>
                  <hr />
                </li>
              )
            })}
          </ol>
        </Container>

        {previousPagePath && (
          <>
            <Link to={previousPagePath}>Previous page</Link>
            <br />
          </>
        )}
        {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
      </Layout>
    </Providers>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
      }
    }
  }
`
